#apple-maps {
  height: 100vh;
}

#apple-map {
  width: 100%;
  height: 250px;
  display: none;
}

#header {
  position: absolute; 
  top: 10px; 
  left:  10px; 
  z-index: 3; 
  background-color: rgba(255,255,255,.75);
  box-shadow: 0 0 0 .5px rgba(0,0,0,.05);
  padding: 5px; 
  border-radius: 9px;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  color: rgb(112, 113, 108);
}

#details {
  position: absolute; 
  top: 10px; 
  right:  10px; 
  z-index: 3; 
  background-color: rgba(255,255,255,.75);
  box-shadow: 0 0 0 .5px rgba(0,0,0,.05);
  padding: 5px; 
  border-radius: 9px;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  color: rgb(112, 113, 108);
}

#categories {
  position: absolute; 
  top: 100px; 
  left:  0px; 
  z-index: 3; 
  background-color: rgba(255,255,255,.75);
  box-shadow: 0 0 0 .5px rgba(0,0,0,.05);
  padding: 5px; 
  border-radius: 9px;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  color: rgb(112, 113, 108);
}

#categories.mobileApp, #categoriesSlider.mobileApp {
  top: 0px !important;
}

#categories a, #categoriesSlider a {
  font-size: 18px;
}

#categoriesSlider {
  position: absolute; 
  top: 100px; 
  left:  -10px; 
  z-index: 3; 
  background-color: rgba(255,255,255,.75);
  box-shadow: 0 0 0 .5px rgba(0,0,0,.05);
  padding: 5px; 
  border-radius: 0px 9px 9px 0px;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  color: rgb(112, 113, 108);
}

#topHeader {
  position: absolute; 
  top: 10px; 
  left:  30%; 
  z-index: 3; 
  background-color: rgba(255,255,255,.75);
  box-shadow: 0 0 0 .5px rgba(0,0,0,.05);
  padding: 5px; 
  border-radius: 9px;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  color: rgb(112, 113, 108);
}


@media (max-width: 800px) {
  #header img, #header-add img {
    width: 30px;
    margin: 0 auto;
  }

  #header span {
    font-size: 12px;
  }

  .modal-content {
    width: 90%;
  }

  #details {
    max-width: 100%;
    right: 0px;
  }
}

@media (min-width: 801px) {
  #header img, #header-add img {
    width: 50px;
    margin: 0 auto;
  } 

  .modal-content {
    width: 40%;
  }
}


#footer {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  z-index: 3;
  background-color: rgba(255,255,255,.75);
  box-shadow: 0 0 0 .5px rgba(0,0,0,.05);
  padding: 5px; 
  border-radius: 9px;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  color: rgb(112, 113, 108);
  text-align: center;
}

#footer img {
  height: 25px;
  display: inline;
}

.problem-container {
  display: inline;
  float: right;
  margin-right: 5px;
  color: white;
  background-color: red;
  border-radius: 5px; 
  font-size: 12px;          
}

.problem-container a {
  padding: 5px;
}


/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {top:-300px; opacity:0} 
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}

/* The Close Button */
.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-header {
  padding: 2px 16px;
  background-color: rgb(202,125,70);
  color: white;
  min-height: 45px;
}

.modal-header h2 {
  margin-top: 10px;
  font-weight: bold;
}

.modal-body {padding: 2px 16px;}